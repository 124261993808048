import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "บทนำ"
    }}>{`บทนำ`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "country-life--farming-season"
    }}>{`Country Life : Farming Season`}</h2>
    <p>{`ยินดีต้อนรับ Farmer ทุกท่านเข้าสู่โลกของ Country Life : Farming Season ที่ทุกท่านจะได้เพลิดเพลินไปกับการทำสวน เลี้ยงสัตว์ ทำภารกิจ และตื่นเต้นไปกับการเก็บสะสมตัวละคร ที่มีความสามารถหายาก !`}</p>
    <p><img alt="intro" src={require("./public/images/main.png")} /></p>
    <p>{`Farming Season เป็นเกม Play and Earn เราต้องการให้ผู้เล่นของเราสนุกไปกับการเล่นและอาจจะทำรายได้ได้บ้าง แต่การเล่นแล้วรวย ไม่ใช่จุดประสงค์ของเรา ถ้าคุณคือมือใหม่ในโลกเกมคริปโต อยากสะสม NFT แต่ไม่รู้จะทำยังไง นี่คือเกมสำหรับคุณเลยหละ หรือถ้าคุณเป็นมือเก๋า คุณก็จะรักเกมนี้เช่นกัน ทำใจให้สบาย อย่าซีเรียสเรื่องผลตอบแทน แล้วสนุกไปด้วยกันนะ`}</p>
    <blockquote>
      <p parentName="blockquote">{`ในเกม Country Life : Farming Season ผู้เล่นสามารถได้รับเหรียญ CLC จากเกมนี้ได้ผ่านการทำเควส และการขายผลผลิตพิเศษ`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "เว็บไซต์หลักและโซเชียลมีเดีย"
    }}>{`เว็บไซต์หลักและโซเชียลมีเดีย`}</h2>
    <ul>
      <li parentName="ul">{`เว็บไซต์เกม - `}<a parentName="li" {...{
          "href": "https://www.season.town/"
        }}>{`https://www.season.town/`}</a></li>
      <li parentName="ul">{`Facebook - `}<a parentName="li" {...{
          "href": "https://www.facebook.com/groups/611627083492022"
        }}>{`Farming Season Official Group`}</a></li>
      <li parentName="ul">{`ติดต่อเรา - `}<a parentName="li" {...{
          "href": "https://www.facebook.com/progaming.co.th"
        }}>{`ProGaming`}</a></li>
      <li parentName="ul">{`แจ้งปัญหา - `}<a parentName="li" {...{
          "href": "https://share.hsforms.com/16xae8YKwRJ6qA_0BPzc6nQ3tki0"
        }}>{`Report Form`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      